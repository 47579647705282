const AppId = '96cd10d3-de67-42d5-b2d7-a8ca0095e5ab';
// const AdminServer = 'http://admin.dev.in-store.hk:84'; // dev
// const AdminServer = 'https://admin.uat.intimex.hk'; // Uat
const AdminServer = 'https://admin.uat.in-store.hk'; // Produciton
module.exports = {
  // instore app id admin的id
  AppId: AppId,
  // api server url 統一配置Api服務器的url
  // ApiServer: 'http://api.dev.in-store.hk:84', // dev
  // ApiServer: 'https://api.uat.intimex.hk', // Uat
  ApiServer: 'https://api.uat.in-store.hk', // Production
  // api version for this app 統一配置Api版本號
  ApiVersion: 'V2',
  // api Authorization 身份认证，用于后端识别客户端
  Authorization: 'OTZjZDEwZDMtZGU2Ny00MmQ1LWIyZDctYThjYTAwOTVlNWFiOmMyODc5ZDUzLWM2ZWQtNGFlYS05YzIyLTNiZjI0NWIyZTQwYQ==',
  // admin server url, for login management platform 統一配置admin服務器

  AdminServer: AdminServer,
  // url '/admin' auto open admin tab
  AutoOpenAdmin: true,
  // admin login url 統一配置admin登錄地址
  AdminLoginUrl: AdminServer + '/default/ClientLogin/' + AppId,
  // 前端默认配置
  FrontE: {
    mobileBuilding: false,
    pcBuilding: false
  }
};
